<template>
  <Layout>
    <div class="ppcontainer regcontainer">
      <Header
        :back="true"
        regulation="pipl"
      />
      <h1
        class="title"
        :dir="dir"
      >
        权限说明
      </h1>
      <div class="text notitle">
        为了保证您正常的游戏体验，将向您申请获取以下权限：<br><br>
        储存权限：下载和安装游戏更新内容, 拒绝授权将可能无法正常进行游戏<br><br>
        电话权限：需要申请电话权限以获取设备标识(AndroidID、mac)，生成帐号、保存和恢复游戏数据<br><br>
        后台运行权限：允许程序在手机屏幕关闭后后台进程仍然运行<br><br>
        网络状态权限：允许获取当前WiFi接入的状态以及WLAN热点的信息<br><br>
        获取应用安装列表权限：收集应用程序性能、崩溃等情况，为了预防恶意程序以及安全运营所必需 （注：如您希望使用游戏中的第三方功能/服务，为确保您能正常使用相应功能/服务，该第三方可能会主动核实并向我们发送您的应用安装列表信息，但您理解我们不会收集和使用被动接收到的该等信息）<br><br>
        麦克风权限：需要访问麦克风，以提供语音服务功能
      </div>
      <h1 :dir="dir">
        以下是oppo渠道集成所需权限
      </h1>
      <div class="text scrollable">
        <table>
          <tr>
            <td>
              <h3>调用的设备权限</h3>
            </td>
            <td>
              <h3>权限对应的业务功能</h3>
            </td>
            <td>
              <h3>调用权限目的</h3>
            </td>
          </tr>
          <tr>
            <td>
              获取App账户
            </td>
            <td>
              OPPO游戏联运SDK
            </td>
            <td>
              用于获取登录帐号，使得App可以登录游戏
            </td>
          </tr>
          <tr>
            <td>
              访问粗略位置
            </td>
            <td>
              广告投放、反作弊
            </td>
            <td>
              精准广告投放及反作弊
            </td>
          </tr>
          <tr>
            <td>
              访问精确位置
            </td>
            <td>
              广告投放、反作弊
            </td>
            <td>
              广告投放及反作弊
            </td>
          </tr>
          <tr>
            <td>
              读取外部存储空间
            </td>
            <td>
              素材展示、应用下载
            </td>
            <td>
              广告素材读取及应用下载类广告投放
            </td>
          </tr>
          <tr>
            <td>
              写入外部存储空间
            </td>
            <td>
              素材展示、应用下载
            </td>
            <td>
              广告素材存储及应用下载广告投放
            </td>
          </tr>
          <tr>
            <td>
              获取应用安装列表
            </td>
            <td>
              广告投放
            </td>
            <td>
              应用下载广告投放
            </td>
          </tr>
          <tr>
            <td>
              请求安装应用
            </td>
            <td>
              广告投放
            </td>
            <td>
              应用下载广告投放
            </td>
          </tr>
        </table>
      </div>
    </div>
  </Layout>
</template>

<script>
import Header from '@components/Header.vue'

export default {
  metaInfo() {
    return {
      title: 'Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    Header,

  },
  data() {
    return {
      content: {},
      orientation: '',
      lang: '',
    }
  },
  created() {
    this.content = this.$compliance.getLocalisedContent()
  },
  mounted() {
    this.orientation = this.$compliance.screenOrientation
    this.lang = this.$compliance.lang
    this.$compliance.forceRedraw()
  },
}
</script>

<style lang="scss">
.ppcontainer {
  position: relative;
  padding: 10px 0 10px 0;
  background: #fff;
  width: 100%;

  .logo {
    width: 20%;
    height: 40px;
    max-width: 130px;
    margin: 20px auto 0 auto;
    background-image: url('../../../assets/images/logo.jpg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .header {
    width: calc(100% - 60px);
    height: auto;
    margin: 0 30px;
  }
  .v-lazy-image {
    filter: blur(10px);
    transition: filter 0.7s;
  }
  .v-lazy-image-loaded {
    filter: blur(0);
  }
  .title {
    text-transform: uppercase;
    color: #4f5c68;
    font-size: 1.8em;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 10px auto 10px auto;

    @media screen and (min-width: 768px) {
      font-size: 3.2em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .text {
    padding: 0 10px 20px 10px;
    font-size: 1em;
    color: #4c5d6a;
    text-align: justify;
    &.noheader {
      margin: 0;
    }
    &.notitle {
      margin: 20px 0 0 0;
    }
    span {
      color: #688194;
      text-decoration: underline;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8em;
      padding: 0 10px 20px 10px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.1em;
    }
  }
  .scrollable {
    overflow-x: scroll;
    td {
      min-width: 240px;
    }
  }
}
.absolutefooter .tcf20container {
  height: 100%;
}
</style>
